<template>
  <Fold title="공급사">
    <Board
      :disableHover="true"
      :isScroll="true"
      scrollMaxHeight="342px"
      :dataList="dataList"
      :showPagination="false"
    >
      <!-- prettier-ignore -->
      <template v-slot:colgroup>
        <col
          v-if="isUseGrade"
          style=""
        ><!-- 회사명 -->
        <col
          v-else
          style=""
        ><!-- 회사명 -->
        <col
          v-if="isUseGrade"
          style="width:355px;"
        ><!-- 기업신용평가등급 -->
        <col style="width:389px;"><!-- 카탈로그 -->
      </template>
      <template v-slot:tr>
        <th v-if="isUseGrade" style="width: 496px">회사명</th>
        <th v-else style="width: 851px">회사명</th>
        <th v-if="isUseGrade" style="width: 355px">기업신용평가등급</th>
        <th style="width: 389px" />
      </template>
    </Board>
  </Fold>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import Board from "@/components/common/board/Board";
import SupplyCompanyListLine from "./SupplyCompanyListLine";

export default {
  components: {
    Fold,
    Board,
    SupplyCompanyListLine,
  },
  props: {
    dataList: Array,
  },
  computed: {
    isUseGrade() {
      // 기업신용평가 임시 삭제 - https://dkt.agit.in/g/300032199/wall/341951956
      //return LocalStorageManager.shared.getPlanItemCode() != 'k-lite';
      return false; //LocalStorageManager.shared.getPlanItemCode() != '';
    },
  },
};
</script>

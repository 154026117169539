<template>
  <Fold title="기본정보">
    <TableView>
      <template v-slot:body>
        <tr>
          <th>견적요청번호</th>
          <td>
            <div class="row_pack">
              <span class="txt_view">{{ itemData.estimateNum }}</span>
              <div class="area_right">
                <span :class="['badge_state', stateType]">{{ itemData.statusName }}</span>
              </div>
            </div>
          </td>
          <th>견적요청일자</th>
          <td>{{ itemData.regDate | dateStringFormat }}</td>
        </tr>
        <tr>
          <th>견적요청작성자</th>
          <td>{{ itemData.reqName }}</td>
          <th>구매사담당자</th>
          <td>{{ itemData.purchaseManagerName }}</td>
        </tr>
        <tr>
          <th>견적요청명</th>
          <td v-html="itemData.title"></td>
          <th>이전견적</th>
          <td>
            <button
              :class="['link_subject']"
              @click="$windowOpen(`${$routerPath.ESTIMATE_VIEW}/${itemData.upEstimateNum}`)"
              v-html="itemData.upTitle"
            />
          </td>
        </tr>
        <!-- <tr>
          <th>상세내용</th>
          <td class="h440" colspan="3" v-html="itemData.detail"></td>
        </tr>-->
      </template>
    </TableView>
  </Fold>
</template>

<script>
import TableView from "@/components/shared/tableView/TableView";
import Fold from "@/components/common/fold/Fold";
import { makePathWithQuery } from "@/utils/urlUtils";

export default {
  name: "EstimateInfo",
  components: {
    TableView,
    Fold,
  },
  props: {
    itemData: Object,
  },
  computed: {
    toPath() {
      const { upEstimateNum } = this.itemData;
      const path = this.$routerPath.ESTIMATE_VIEW;
      const ret = makePathWithQuery(path, upEstimateNum, this.$route.query);
      return ret;
    },
    stateType() {
      const { statusName } = this.itemData;

      switch (statusName) {
        case "발송완료":
          return "badge_blue";
        case "견적회신":
          return "badge_blue";
        case "견적요청":
          return "badge_purple";
      }
      return "";
    },
  },
};
</script>

<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">견적요청상세</h2>
      </div>
      <div class="body_section">
        <EstimateInfo :itemData="itemData" />
        <Fold title="파트너사" :isEssential="true">
          <Board
            :disableHover="true"
            :dataList="itemData.estimatePartnerList"
            :showPagination="false"
          >
            <template v-slot:colgroup>
              <col style="width: 254px" />
              <col style="width: 212px" />
              <col style="width: 212px" />
              <col style="width: 224px" />
            </template>
            <template v-slot:tr>
              <th>회사명</th>
              <th>대표자명</th>
              <th>사업자등록번호</th>
              <th>대표이메일</th>
            </template>
            <template v-slot:rows>
              <template v-for="item in itemData.estimatePartnerList">
                <tr :key="item.personNum">
                  <td class="td_ellip">
                    <button
                      class="link_subject"
                      :data-ellip="item.companyName || ''"
                      @click="$windowOpen(`${$routerPath.PARTNER_VIEW}/${item.userNum}`)"
                    >
                      {{ item.companyName || "" }}
                    </button>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.corporateNum }}</td>
                  <td class="td_ellip align_left" :data-ellip="item.email">
                    {{ item.email }}
                  </td>
                </tr>
              </template>
            </template>
            <template v-slot:emptyList>
              <tr>
                <td colspan="4" class="td_empty">
                  선택한 업체가 없습니다.
                  <!-- <div class="area_right">
                <button type="button" disabled="disabled" class="btn_fourthly btn_small">삭제</button>
            </div> -->
                </td>
              </tr>
            </template>
          </Board>
        </Fold>
        <Fold title="상세내용">
          <TableView>
            <template v-slot:body>
              <tr>
                <th>상세내용</th>
                <td colspan="3" v-html="contents" />
              </tr>
            </template>
          </TableView>
        </Fold>
        <Fold title="견적상세품목">
          <Board :disableHover="true" :dataList="itemData.estimateItemList" :showPagination="false">
            <template v-slot:colgroup>
              <col style="width: 62px" />
              <col />
              <col style="width: 200px" />
            </template>
            <template v-slot:tr>
              <th>No.</th>
              <th>품명</th>
              <th>수량</th>
            </template>
            <template v-slot:rows>
              <template v-for="(item, idx) in itemData.estimateItemList">
                <tr :key="item.itemCode">
                  <td>{{ idx + 1 }}</td>
                  <td class="align_left">
                    <button
                      class="link_subject"
                      @click="$windowOpen(`${$routerPath.ITEM_VIEW}/${item.itemCode}`)"
                    >
                      {{ item.itemName }}
                    </button>
                  </td>
                  <td class="align_right">{{ item.quantity | currency }}</td>
                </tr>
              </template>
            </template>
            <template v-slot:emptyList>
              <tr>
                <td colspan="3" class="td_empty">
                  선택한 품목이 없습니다.
                  <!-- <div class="area_right">
                <button type="button" disabled="disabled" class="btn_fourthly btn_small">삭제</button>
            </div> -->
                </td>
              </tr>
            </template>
          </Board>
        </Fold>

        <!-- <EstimateReply
          v-if="itemData.estimateList && itemData.estimateList.length > 0"
          :dataList="itemData.estimateList"
          @onClickEstimatePrint="onClickEstimatePrint"
        /> -->
      </div>
    </div>

    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_tertiary btn_large" @click="onClickGoToList">목록</button>
      </div>
      <div class="area_right">
        <button
          type="button"
          class="btn_primary btn_large"
          :disabled="checkSelectedComp"
          @click="onClickGoToResave"
        >
          재견적
        </button>
      </div>
    </Sticky>

    <template v-slot:popup>
      <!--      <ClientStraightPopup-->
      <!--        v-if="isPopClientStraight"-->
      <!--        :clientData="clientData"-->
      <!--        @onClickClose="onClickCloseClientStraightPop"-->
      <!--        @setData="getData($route.params.id)"-->
      <!--      />-->
      <EstimatePrintPopup
        v-if="isPopEstimatePrint"
        :estimateNo="estimatePrintSelected ? estimatePrintSelected.estimateNo : null"
        :prchsrMngrNm="itemData.prchsrMngrNm"
        @onClickClose="onClickClosePopEstimatePrint"
      />
      <!-- :itemData="printItemData"
      :goodDataList="goodDataList"-->
      <ScorePopup
        v-if="isPopScore"
        :widthPopScore="widthPopScore"
        :itemData="companyCreditData"
        @onClickClose="onClickCloseScorePop"
      />
      <CatalogListPopup
        v-if="selectedCompany"
        v-show="!selectedCatalog"
        :companyNo="selectedCompany ? selectedCompany.spplrCompanyRgstNo : ''"
        @onClickCatalogLine="onClickCatalogLine"
        @onClickClose="onClickCloseCatalogList"
      />
      <CatalogDetailPopup
        v-if="selectedCompany && selectedCatalog"
        :brochureSeq="selectedCatalog.brochureSeq"
        @onClickClose="onClickCloseCatalogDetail"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableView from "@/components/shared/tableView/TableView";
import Board from "@/components/common/board/Board";
import Fold from "@/components/common/fold/Fold";
import Sticky from "@/components/layout/content/Sticky.vue";

import ScorePopup from "@/components/admin/estimate/popup/ScorePopup";
import CatalogListPopup from "@/components/admin/estimate/popup/CatalogListPopup";
import CatalogDetailPopup from "@/components/admin/estimate/popup/CatalogDetailPopup";

import EstimateInfo from "@/components/shared/estimate/view/EstimateInfo";
import SupplyCompanies from "@/components/admin/estimate/view/SupplyCompanies";
import EstimateReply from "@/components/admin/estimate/view/EstimateReply";

import EstimatePrintPopup from "@/components/admin/estimate/popup/EstimatePrintPopup";
// import ClientStraightPopup from '@/components/layout/popup/ClientStraightPopup';

import PageMixin from "@/mixins/PageMixin";
import CreditMixin from "@/mixins/CreditMixin";
import PopCatalogMixin from "@/mixins/estimate/PopCatalogMixin";
import ClientStraightMixin from "@/mixins/ClientStraightMixin";

import ApiService from "@/services/ApiService";
import { ALERT_ACTION } from "@/store/modules/alert/action";
import { getContractDept, escapeRegExp } from "@/utils/spUtils";

export default {
  components: {
    PageWithLayout,
    TableView,
    Board,
    Fold,
    Sticky,
    EstimateInfo,

    ScorePopup,
    CatalogListPopup,
    CatalogDetailPopup,

    SupplyCompanies,
    EstimateReply,

    EstimatePrintPopup,
    // ClientStraightPopup,
  },
  mixins: [PageMixin, CreditMixin, PopCatalogMixin, ClientStraightMixin],
  data() {
    return {
      itemData: {},
      checkSelectedComp: false,
      estimatePrintSelected: null,

      isPopEstimatePrint: false,

      widthPopScore: "877px",
      // scoreData: {companyName:'(주) Baaaa 업체명', ceo:'김남철', registrationNum:'110111-167234',corporateNum:'107-81-67484',address:'서울특별시 종로구 새문안로 4길 19', address2:'10층 Baaaa',regDate:'20191231',evaluateDate:'20191231',periodDate:'20191231',score:'AA+' },
      progressDataList: [
        { stateType: "done", stateName: "견적요청" },
        { stateType: "ing", stateName: "발주대기" },
        { stateType: "", stateName: "입고/검수 대기" },
      ],
    };
  },
  computed: {
    contents() {
      if (!this.itemData.contents) return;
      let target = this.itemData.contents;
      return escapeRegExp(target);
    },
  },
  created() {
    const { id } = this.$route.params;

    if (!id) {
      this.$router.push(this.$routerPath.HOME);
      return;
    }

    this.getData(id);
  },
  methods: {
    async getData(id) {
      const path = `${this.$apiPath.ESTIMATE}/${id}`;
      const result = await ApiService.shared.getData(path);

      const { code, data, message } = result;
      if (code !== "200") {
        await this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);
        return;
      }

      this.itemData = data;
      const { estimateNum } = this.itemData;
      this.getSelectedCompData(estimateNum);
    },

    async getSelectedCompData(estimateNum) {
      const path = `${this.$apiPath.QUOTATION}?type=list&estimateNum=${estimateNum}&startDate=20210101&endDate=20221231`;
      const result = await ApiService.shared.getData(path);
      const { code, data, message } = result;
      if (code !== "200") {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);
        return;
      }

      data.forEach((partner) => {
        if (partner.status == "S") {
          this.checkSelectedComp = true;
        }
      });
    },

    onClickPopScore(rowData) {
      const companyNo = rowData.spplrCompanyRgstNo || rowData.clientCompanyRgstNo;
      this.getCreditData(companyNo);
    },
    onClickEstimatePrint(item) {
      // 견적서 api 호출.

      // this.printItemData.supplyCompanyName = item.companyName;

      this.estimatePrintSelected = item;

      this.isPopEstimatePrint = true;
    },
    onClickGoToList() {
      this.goToList();
    },
    onClickGoToResave() {
      this.goToResave();
    },
    goToList() {
      this.$router.push({
        path: this.$routerPath.ESTIMATE_LIST,
        query: this.$route.query,
      });
    },
    goToResave() {
      this.$router.push({ path: `${this.$routerPath.ESTIMATE_MODIFY}/${this.$route.params.id}` });
    },

    onClickClosePopEstimatePrint() {
      this.isPopEstimatePrint = false;
    },
  },
};
</script>

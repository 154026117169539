<template>
  <tr>
    <td>
      <button
        v-if="rowData.clientYn"
        class="btn_client btn_my_client"
        @click="$emit('onClickClientStraight')"
      >
        {{ rowData.companyName }}
      </button>
      <span v-else>{{ rowData.companyName }}</span>
    </td>
    <!-- 신용 평가도 팝업? -->
    <td v-if="isUseGrade" class="td_grade">
      <span class="txt_grade">
        <span :class="['ico_grade', 'ico_' + gradeType + '_s']" />{{
          gradeType == "except" ? "평가등급이 없습니다" : rowData.createPoint + " 점"
        }}
      </span>
    </td>
    <!-- <td>{{ rowData.tradingYn ? '거래처' : '비거래처' }}</td> -->
    <td class="align_right">
      <div class="row_pack">
        <button
          v-if="!rowData.clientYn"
          type="button"
          class="btn_tertiary btn_small"
          @click="$emit('onClickClientStraight')"
        >
          거래처등록
        </button>
        <button
          v-if="isUseGrade"
          type="button"
          class="btn_tertiary btn_small"
          @click="$emit('onClickScore')"
        >
          기업신용평가정보
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    rowData: Object,
    isUseGrade: Boolean,
  },
  computed: {
    brochureCnt() {
      if (this.rowData.brochureCnt <= 0) return;
      if (this.rowData.brochureCnt < 100) {
        return this.rowData.brochureCnt;
      } else {
        return "99+";
      }
    },
    gradeType() {
      const { createPoint } = this.rowData;

      if (createPoint == 0) {
        return "except";
      } else if (createPoint > 0 && createPoint < 20) {
        return "bankrupt";
      } else if (createPoint >= 20 && createPoint < 50) {
        return "bad";
      } else if (createPoint >= 50 && createPoint < 80) {
        return "normal";
      } else if (createPoint >= 80 && createPoint < 90) {
        return "good";
      } else if (createPoint >= 90) {
        return "excellence";
      }
      return "except";
    },
  },
};
</script>

<template>
  <Fold title="회신받은 견적서">
    <Board :disableHover="true" :dataList="dataList" :showPagination="false">
      <template v-slot:colgroup>
        <col style="width: 150px" />
        <!-- 견적서번호 -->
        <col style="width: 100px" />
        <!-- 견적일자 -->
        <col style="width: 300px" />
        <!-- 견적서명 -->
        <col style="width: 175px" />
        <!-- 공급사 -->
        <col style="width: 150px" />
        <!-- 공급사담당자 -->
        <col style="width: 155px" />
        <!-- 견적금액 (공급가액+세액) -->
        <col style="width: 110px" />
        <!-- 발주서발행여부 -->
        <col style="width: 100px" />
        <!-- 견적서 -->
      </template>
      <template v-slot:tr>
        <th>견적서번호</th>
        <th>견적일자</th>
        <th>견적서명</th>
        <th>공급사</th>
        <th>공급사담당자</th>
        <th>견적금액 (공급가액+세액)</th>
        <th>발주서발행여부</th>
        <th>견적서</th>
      </template>
      <template v-slot:rows>
        <template v-for="(item, index) in dataList">
          <tr :key="index">
            <td>{{ item.estimateNo }}</td>
            <td>{{ item.estimateDt | dateStringFormat }}</td>
            <td>{{ item.estimateTitle }}</td>
            <td>{{ item.spplrCompanyNm }}</td>
            <td>{{ item.spplrMngrNm }}</td>
            <td>{{ item | money(item.estimatePrice) }}</td>
            <td>{{ item.statusCode && item.statusCode != "C" ? "발주서발행" : "발주서미발행" }}</td>
            <td>
              <button
                type="button"
                class="btn_tertiary btn_small"
                @click="$emit('onClickEstimatePrint', item)"
              >
                보기
              </button>
            </td>
          </tr>
        </template>
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="8" class="td_empty">회신받은 견적서가 없습니다.</td>
        </tr>
      </template>
    </Board>
  </Fold>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import Board from "@/components/common/board/Board";

export default {
  components: {
    Fold,
    Board,
  },
  props: {
    dataList: Array,
  },
  // data() {
  //   return {
  //     estimateDataList:[
  //       { no:'171012165356',title:'A품목 견적서 회신건',date:'2020-04-25',companyName:'Baaa 업체명',manager:'김남철',price:'₩ 100,100'},
  //       { no:'171012165357',title:'B품목 견적서 회신건',date:'2020-04-25',companyName:'Caaa 업체명',manager:'배선호',price:'₩ 100,100'},
  //       { no:'171012165358',title:'C품목 견적서 회신건',date:'2020-04-27',companyName:'Daaa 업체명',manager:'유보현',price:'₩ 100,100'},
  //       { no:'171012165359',title:'D품목 견적서 회신건',date:'2020-04-27',companyName:'Eaaa 업체명',manager:'구본국',price:'₩ 100,100'},
  //     ],
  //   };
  // },
};
</script>
